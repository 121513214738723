// src/components/SidebarMenu.tsx
import { useContext } from 'react';
import {
  ListSubheader,
  alpha,
  Box,
  List,
  styled,
  Button,
  ListItem,
  SpeedDialIcon,
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';
import EditCalendarIcon from '@mui/icons-material/CalendarMonth';
import {
  AttachMoney,
  BusinessOutlined,
  ChatBubble,
  FileUploadSharp,
  Interests,
  LocationSearchingTwoTone,
  Person,
  PersonAddAlt1Sharp,
  Topic
} from '@mui/icons-material';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ReceiptIcon from '@mui/icons-material/Receipt';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'src/hooks/useAuth';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
      'transform',
      'opacity'
    ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

// Define the menu structure
const menuSections = [
  {
    subheader: "dashboard",
    items: [
      {
        to: "/dashboard/charts",
        icon: <InsertChartIcon />,
        label: "overview",
        allowedRoles: ["SuperAdmin", "FranchiseAdmin"]
      },
      {
        to: "/management/todos",
        icon: <EventBusyIcon />,
        label: "to_dos",
        allowedRoles: ["SuperAdmin", "FranchiseAdmin", "LocationAdmin", "Teacher"]
      }
    ]
  },
  {
    subheader: "calendar",
    items: [
      {
        to: "/dashboard/calendar",
        icon: <EditCalendarIcon />,
        label: "calendar",
        allowedRoles: ["SuperAdmin", "FranchiseAdmin", "LocationAdmin", "Teacher", "Parent", "Student"]
      },
      {
        to: "/management/daysoff",
        icon: <EventBusyIcon />,
        label: "plan_free_days",
        allowedRoles: ["SuperAdmin", "FranchiseAdmin", "LocationAdmin"]
      }
    ]
  },
  {
    subheader: "leads",
    items: [
      {
        to: "/management/leads",
        icon: <LocationOnIcon />,
        label: "manage_leads",
        allowedRoles: ["SuperAdmin"]
      },
      {
        to: "/dashboard/interests",
        icon: <FileUploadSharp />,
        label: "view_contact_forms",
        allowedRoles: ["SuperAdmin"]
      }
    ]
  },
  {
    subheader: "franchise_management",
    items: [
      {
        to: "/management/franchises",
        icon: <BusinessOutlined />,
        label: "franchise_locations",
        allowedRoles: ["SuperAdmin", "FranchiseAdmin"]
      },
      {
        to: "/management/franchise-admins",
        icon: <LocationSearchingTwoTone />,
        label: "franchise_admins",
        allowedRoles: ["SuperAdmin", "FranchiseAdmin"]
      },
      {
        to: "/management/billings",
        icon: <AttachMoney />,
        label: "franchise_invoices",
        allowedRoles: ["SuperAdmin", "FranchiseAdmin"]
      }
    ]
  },
  {
    subheader: "finance",
    items: [
      {
        to: "/management/invoices",
        icon: <ReceiptIcon />,
        label: "monthly_invoices",
        allowedRoles: ["FranchiseAdmin", "Teacher", "Parent"]
      }
    ]
  },
  {
    subheader: "location_management",
    items: [
      {
        to: "/management/locations",
        icon: <LocationOnIcon />,
        label: "Location",
        allowedRoles: ["SuperAdmin", "FranchiseAdmin", "LocationAdmin"]
      },
      {
        to: "/management/location-admins",
        icon: <LocationOnIcon />,
        label: "location_admins",
        allowedRoles: ["SuperAdmin", "FranchiseAdmin", "LocationAdmin"]
      },
      {
        to: "/management/teachers",
        icon: <Person />,
        label: "teachers",
        allowedRoles: ["SuperAdmin", "FranchiseAdmin", "LocationAdmin"]
      },
      {
        to: "/management/parents",
        icon: <PersonAddAlt1Sharp />,
        label: "parents",
        allowedRoles: ["SuperAdmin", "FranchiseAdmin", "LocationAdmin"]
      },
      {
        to: "/management/students",
        icon: <PersonAddAlt1Sharp />,
        label: "students",
        allowedRoles: ["SuperAdmin", "FranchiseAdmin", "LocationAdmin", "Parent"]
      }
    ]
  },
  {
    subheader: "communication",
    items: [
      {
        to: "/management/chat",
        icon: <ChatBubble />,
        label: "chatroom",
        allowedRoles: ["SuperAdmin", "FranchiseAdmin", "LocationAdmin", "Teacher", "Parent", "Student"]
      }
    ]
  },
  {
    subheader: "files_documents",
    items: [
      {
        to: "/management/files",
        icon: <FileUploadSharp />,
        label: "files",
        allowedRoles: ["SuperAdmin", "FranchiseAdmin", "Parent", "Teacher", "Student"]
      }
    ]
  },
  {
    subheader: "surveys_builder",
    items: [
      {
        to: "/management/surveys-builder",
        icon: <SpeedDialIcon />,
        label: "surveys_builder",
        allowedRoles: ["SuperAdmin", "FranchiseAdmin"]
      }
    ]
  },
  {
    subheader: "reports",
    items: [
      {
        to: "/management/session-reports",
        icon: <DescriptionOutlinedIcon />,
        label: "session_reports",
        allowedRoles: ["Parent", "Student"]
      }
    ]
  },
  {
    subheader: "settings",
    items: [
      {
        to: "/management/contracts",
        icon: <Topic />,
        label: "contracts",
        allowedRoles: ["SuperAdmin", "FranchiseAdmin"]
      },
      {
        to: "/management/topics",
        icon: <Topic />,
        label: "topics",
        allowedRoles: ["SuperAdmin", "FranchiseAdmin"]
      },
      {
        to: "/management/contracts", // Consider updating this route if needed
        icon: <ReceiptIcon />,
        label: "system_settings",
        allowedRoles: ["SuperAdmin", "FranchiseAdmin"]
      }
    ]
  }
];

function SidebarMenu() {
  const { closeSidebar } = useContext(SidebarContext);
  const { t } = useTranslation();
  const { userRoles } = useAuth(); // Get current user's roles

  // Function to check if any of the user's roles are allowed for an item
  const isItemAllowed = (allowedRoles: string[]) => {
    return allowedRoles.some(role => userRoles.includes(role));
  };

  // Function to filter items based on user roles
  const getAllowedItems = (items: any[]) => {
    return items.filter(item => isItemAllowed(item.allowedRoles));
  };

  return (
    <MenuWrapper>
      {menuSections.map((section, index) => {
        const allowedItems = getAllowedItems(section.items);
        if (allowedItems.length === 0) {
          // Do not render the section if there are no allowed items
          return null;
        }

        return (
          <List
            key={index}
            component="div"
            subheader={
              <ListSubheader component="div" disableSticky>
                {t(section.subheader)}
              </ListSubheader>
            }
          >
            <SubMenuWrapper>
              <List component="div">
                {allowedItems.map((item, idx) => (
                  <ListItem key={idx} component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to={item.to}
                      startIcon={item.icon}
                    >
                      {t(item.label)}
                    </Button>
                  </ListItem>
                ))}
              </List>
            </SubMenuWrapper>
          </List>
        );
      })}
    </MenuWrapper>
  );
}

export default SidebarMenu;
