import React, { useState, ChangeEvent } from 'react';
import {
  Avatar,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Hidden,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  TextField,
  Tooltip,
  Typography,
  Button
} from '@mui/material';
import { lighten, styled } from '@mui/material/styles';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone';
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import { fetchAllUser, fetchSelectedUser } from 'src/services/userService';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const DialogWrapper = styled(Dialog)(
  () => `
    .MuiDialog-container {
        height: auto;
    }
    
    .MuiDialog-paperScrollPaper {
        max-height: calc(100vh - 64px)
    }
`
);

const SearchInputWrapper = styled(TextField)(
  ({ theme }) => `
    background: ${theme.colors.alpha.white[100]};

    .MuiInputBase-input {
        font-size: ${theme.typography.pxToRem(17)};
    }
`
);

const DialogTitleWrapper = styled(DialogTitle)(
  ({ theme }) => `
    background: ${theme.colors.alpha.black[5]};
    padding: ${theme.spacing(3)}
`
);

function HeaderSearch() {
  const [openSearchResults, setOpenSearchResults] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [openDetailsDialog, setOpenDetailsDialog] = useState(false);

  const handleSearchChange = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const value = event.target.value;
    setSearchValue(value);

    if (value) {
      setOpenSearchResults(true);
      setLoading(true);
      try {
        const response = await fetchAllUser({ search: value });
        setUsers(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch users');
        setLoading(false);
      }
    } else {
      setOpenSearchResults(false);
      setUsers([]);
    }
  };

  const handleUserClick = async (userId: number) => {
    setDetailsLoading(true);
    try {
      const response = await fetchSelectedUser(userId);
      setSelectedUser(response.data);
      setDetailsLoading(false);
      setOpenDetailsDialog(true);
    } catch (err) {
      console.error('Error fetching user details:', err);
      setDetailsLoading(false);
    }
  };

  const handleCloseDetailsDialog = () => {
    setOpenDetailsDialog(false);
    setSelectedUser(null);
  };

  const renderDetails = (data: any) => {
    return Object.entries(data).map(([key, value]) => (
      <Box key={key} mb={2}>
        <Typography variant="h6" sx={{ textTransform: 'capitalize' }}>
          {key.replace(/([A-Z])/g, ' $1')}:
        </Typography>
        {typeof value === 'object' && value !== null ? (
          <Box pl={2}>{renderDetails(value)}</Box>
        ) : (
          <Typography>{value || 'N/A'}</Typography>
        )}
      </Box>
    ));
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip arrow title="Search">
        <IconButton color="primary" onClick={handleClickOpen}>
          <SearchTwoToneIcon />
        </IconButton>
      </Tooltip>

      <DialogWrapper
        open={open}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="md"
        fullWidth
        scroll="paper"
        onClose={handleClose}
      >
        <DialogTitleWrapper>
          <SearchInputWrapper
            value={searchValue}
            autoFocus={true}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchTwoToneIcon />
                </InputAdornment>
              )
            }}
            placeholder="Search for users..."
            fullWidth
            label="Search"
          />
        </DialogTitleWrapper>
        <Divider />

        {openSearchResults && (
          <DialogContent>
            {loading ? (
              <Box display="flex" justifyContent="center" my={2}>
                <CircularProgress />
              </Box>
            ) : error ? (
              <Typography color="error">{error}</Typography>
            ) : (
              <List disablePadding>
                {users.map((user) => (
                  <ListItem button key={user.id} onClick={() => handleUserClick(user.id)}>
                    <Hidden smDown>
                      <ListItemAvatar>
                        <Avatar
                          sx={{
                            background: (theme) => theme.palette.secondary.main
                          }}
                        >
                          {user.firstName[0]}
                        </Avatar>
                      </ListItemAvatar>
                    </Hidden>
                    <Box flex="1">
                      <Box display="flex" justifyContent="space-between">
                        <Typography
                          sx={{ fontWeight: 'bold' }}
                          variant="body2"
                          component="span"
                        >
                          {`${user.firstName} ${user.lastName}`}
                        </Typography>
                        <Typography
                          component="span"
                          variant="body2"
                          sx={{
                            color: (theme) => lighten(theme.palette.secondary.main, 0.5)
                          }}
                        >
                          {user.phoneNumber}
                        </Typography>
                      </Box>
                    </Box>
                    <ChevronRightTwoToneIcon />
                  </ListItem>
                ))}
              </List>
            )}
          </DialogContent>
        )}
      </DialogWrapper>

      <DialogWrapper
        open={openDetailsDialog}
        onClose={handleCloseDetailsDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Button
            startIcon={<ArrowBackTwoToneIcon />}
            onClick={handleCloseDetailsDialog}
            sx={{ mb: 2 }}
          >
            Back
          </Button>
        </DialogTitle>
        <DialogContent>
          {detailsLoading ? (
            <Box display="flex" justifyContent="center" my={2}>
              <CircularProgress />
            </Box>
          ) : selectedUser ? (
            renderDetails(selectedUser)
          ) : (
            <Typography>No details available</Typography>
          )}
        </DialogContent>
      </DialogWrapper>
    </>
  );
}

export default HeaderSearch;
